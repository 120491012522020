import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Box from "../components/Box/Box";
import GridSection from "../components/GridSection";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo";
import { Paragraph, Paragraph2, Title } from "../components/Styled/Text";
import { clampBuilder } from "../helpers/sizeCalc";
import useWindowSize from "../hooks/useWindowSize";
import { ConfiguratorQuery } from "../types/pages/configurator";
import useListenIframe from "../hooks/useListenIframe";
import OrderCheckout from "../components/OrderCheckout/OrderCheckout";
import { get } from "../constants/theme";
import ConfiguratorIframeBox from "../components/Styled/ConfiguratorIframe";
import Wrapper from "../components/Wrapper";
import GatsbyImageWithArtDirection from "../components/GatsbyImageWithArtDirection";

interface Props extends PageProps {
  data: ConfiguratorQuery;
}

const Configurator = ({ data, location }: Props) => {
  const [isModal, setIsModal] = React.useState<boolean>(false);
  const [isIframeVisible, setIsIframeVisible] = React.useState<
    "iframe-not-visible" | "iframe-visible"
  >("iframe-not-visible");
  const { height } = useWindowSize();
  const { state, closeSubmission, isSubmitting, iframeHeight } =
    useListenIframe();

  const handleModalIsActiveChange = React.useCallback((state) => {
    setIsModal(state);
  }, []);

  const handleIframeInterViewport = React.useCallback(() => {
    setIsIframeVisible("iframe-visible");
  }, []);

  return (
    <>
      <Seo
        title={data.translationsJson.pages.configurator.seo.title}
        description={data.translationsJson.pages.configurator.seo.description}
        keywords={data.translationsJson.pages.configurator.seo.keywords}
        pathname={location.pathname}
        metaImage={
          data.translationsJson.pages.configurator.seo.image.childImageSharp
            .gatsbyImageData
        }
      />
      <Layout pathname={location.pathname}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: height,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              zIndex: 10,
              color: "#ffff",
              userSelect: "none",
              fontFamily: "custom_42263",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                fontFamily: "custom_42266",
                fontSize: "21px",
                fontWeight: 400,
                color: get("palette.text.dark_gold"),
                marginBottom: "30px",
              }}
              as="span"
            >
              {data.translationsJson.pages.configurator.header.subtitle}
            </Box>
            {data.translationsJson.pages.configurator.header.title.map(
              (t, i) => (
                <Title variant="bigHero" key={i} sx={{ color: "white" }}>
                  {t}
                </Title>
              )
            )}

            <Paragraph2
              variant="hero"
              sx={{
                color: "#fff",
                opacity: 0.85,
                textAlign: ["center", "center", "center", "center"],
                maxWidth: [
                  "90%",
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                ],
              }}
            >
              {data.translationsJson.pages.configurator.header.description}
            </Paragraph2>
          </Box>
          <GatsbyImageWithArtDirection
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              height: "100%",
            }}
            imgStyle={{ objectFit: "cover" }}
            desktopImage={data.headerDesktop.childImageSharp.gatsbyImageData}
            mobileImage={data.headerMobile.childImageSharp.gatsbyImageData}
            alt=""
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(12,1fr)",
            textAlign: "center",
            marginTop: [120, 180, 180, 180],
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gridColumnStart: 1,
              gridColumnEnd: 13,
              minHeight: height,
            }}
          >
            <Box>
              <ConfiguratorIframeBox
                key={isIframeVisible}
                viewport={{ once: true }}
                onViewportEnter={handleIframeInterViewport}
                $iframeHeight={iframeHeight}
                src={`https://angry-sinoussi-400096.netlify.app/?locale=${
                  data.translationsJson.pages.configurator.config_module.locale
                    ? data.translationsJson.pages.configurator.config_module
                        .locale
                    : "en_EN"
                }&&bg=white`}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ backgroundColor: get("palette.background.gold") }}>
          <Wrapper
            sx={{
              paddingTop: "109px",
            }}
          >
            <GridSection
              mainContainerSx={{ marginBottom: "20px" }}
              firstChildrenType="textBox"
              secondChildrenType="imageBox"
            >
              <>
                {data.translationsJson.pages.configurator.items[0].title.map(
                  (t, i) => (
                    <Title
                      key={i}
                      sx={{
                        alignSelf: "center",
                        textAlign: "left",
                        width: "100%",
                        marginBottom:
                          i ===
                          data.translationsJson.pages.configurator.items[0]
                            .title.length -
                            1
                            ? "20px"
                            : 0,
                      }}
                      variant="paragraph"
                    >
                      {t}
                    </Title>
                  )
                )}

                {data.translationsJson.pages.configurator.items[0].description.map(
                  (t, i) => (
                    <Paragraph
                      key={i}
                      sx={{
                        textAlign: "left",
                        alignSelf: "center",
                        width: "100%",
                      }}
                    >
                      {t}
                    </Paragraph>
                  )
                )}
              </>
              <>
                <Box
                  as={GatsbyImage}
                  width="100%"
                  height="100%"
                  image={
                    data.translationsJson.pages.configurator.items[0].image
                      .childImageSharp.gatsbyImageData
                  }
                  alt=""
                />
              </>
            </GridSection>

            <GridSection
              mainContainerSx={{ marginBottom: "20px" }}
              firstChildrenType="imageBox"
              secondChildrenType="textBox"
            >
              <>
                <Box
                  as={GatsbyImage}
                  width="100%"
                  height="100%"
                  image={
                    data.translationsJson.pages.configurator.items[1].image
                      .childImageSharp.gatsbyImageData
                  }
                  alt=""
                />
              </>
              <>
                {data.translationsJson.pages.configurator.items[1].title.map(
                  (t, i) => (
                    <Title
                      key={i}
                      sx={{
                        alignSelf: "center",
                        textAlign: "left",
                        width: "100%",
                        marginBottom:
                          i ===
                          data.translationsJson.pages.configurator.items[1]
                            .title.length -
                            1
                            ? "20px"
                            : 0,
                      }}
                      variant="paragraph"
                    >
                      {t}
                    </Title>
                  )
                )}

                {data.translationsJson.pages.configurator.items[1].description.map(
                  (t, i) => (
                    <Paragraph
                      key={i}
                      sx={{
                        textAlign: "left",
                        alignSelf: "center",
                        width: "100%",
                      }}
                    >
                      {t}
                    </Paragraph>
                  )
                )}
              </>
            </GridSection>
          </Wrapper>
        </Box>
      </Layout>
      {isSubmitting && state && (
        <OrderCheckout
          appearAnimationComplete={handleModalIsActiveChange}
          order={state}
          endSubmission={closeSubmission}
        />
      )}
    </>
  );
};

export default React.memo(Configurator);

export const configuratorPageQuery = graphql`
  query ConfiguratorPageQuery {
    site {
      siteMetadata {
        lang
      }
    }
    headerDesktop: file(
      relativeDirectory: { eq: "pages/configurator/header" }
      name: { eq: "desktop" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    headerMobile: file(
      relativeDirectory: { eq: "pages/configurator/header" }
      name: { eq: "mobile" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    translationsJson {
      pages {
        configurator {
          seo {
            title
            description
            keywords
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          header {
            subtitle
            title
            description
          }
          config_module {
            locale
          }
          items {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            imageAlt
          }
        }
      }
    }
  }
`;
